import React from 'react';
import { Typography, Grid, Box, CssBaseline, Button, Paper, Stack, CardActionArea, CardContent, Card } from '@mui/material';
import logo from '../../assets/img/world_of_ev_solutions.png'
import robot from '../../assets/img/robot.png'
import logo2 from '../../assets/img/fondo1.jpeg'
import { useNavigate } from 'react-router-dom';
import {
    Difference,
    SupportAgent,
    Handyman,
    Biotech
} from '@mui/icons-material';
export const HomePage = () => {
    const navigate = useNavigate();
    return (
        <>
            <Grid container component="main" sx={{
                minHeight: '100vh',
                overflowX: 'hidden', // Previene scroll horizontal
                width: '100%'
            }}>
                <CssBaseline />

                {/* Sección de imagen - Oculta en mobile */}
                <Grid
                    item
                    xs={0}
                    sm={6}
                    sx={{
                        height: { sm: '100vh' },
                        backgroundImage: `url(${logo2})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'top',
                    }}
                />

                {/* Sección de contenido */}
                <Grid item xs={12} sm={6} component={Paper} elevation={6} square>
                    <Box sx={{
                        p: { xs: 2, sm: 4, md: 6 },
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        {/* Botón superior */}
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            mb: { xs: 2, sm: 0 }
                        }}>
                            <Button
                                sx={{
                                    borderRadius: '2rem',
                                    textTransform: 'none',
                                    px: 3,
                                    py: 1,
                                    fontSize: '1rem'
                                }}
                                variant='contained'
                                onClick={() => navigate('/account/login')}
                            >
                                Inicia Sesión
                            </Button>
                        </Box>

                        {/* Contenido principal */}
                        <Stack spacing={4} sx={{
                            flex: 1,
                            '& > *': { maxWidth: '100%' } // Previene overflow
                        }}>
                            {/* Encabezado */}
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Box sx={{ maxWidth: 120 }}>
                                        <img src={logo} alt='EV Solucion' style={{ width: '100%', height: 'auto' }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Typography variant='h4' sx={{
                                        fontSize: { xs: '1.5rem', md: '2rem' },
                                        textAlign: { xs: 'center', md: 'left' },
                                        lineHeight: 1.2
                                    }}>
                                        <strong>¡Bienvenidos a EV Solución!</strong>
                                    </Typography>
                                </Grid>
                            </Grid>

                            {/* Texto descriptivo */}
                            <Box sx={{
                                px: { xs: 1, sm: 0 },
                                textAlign: { xs: 'justify', sm: 'left' },
                                lineHeight: 1.6,
                                '& p': {
                                    fontSize: { xs: '0.9rem', sm: '1rem' },
                                    mb: 2
                                }
                            }}>
                                <Typography color='text.secondary'>
                                    Aquí, los soñadores, dueños de laboratorios automotrices, estudiantes y profesionales del sector automotriz encontrarán un espacio dedicado a la innovación y soluciones de vanguardia para la reparación de vehículos híbridos y eléctricos.
                                </Typography>

                                <Typography color='text.secondary'>
                                    Nos comprometemos a ser su guía en el camino hacia una tecnología más limpia y eficiente.
                                </Typography>

                                <Typography color='text.secondary'>
                                    ¡Únase a nosotros y eleve su laboratorio o taller al futuro!
                                </Typography>
                            </Box>

                            {/* Botones principales */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Button
                                        fullWidth
                                        sx={{
                                            borderRadius: '2rem',
                                            py: 1.5,
                                            fontSize: '1rem',
                                            textTransform: 'none'
                                        }}
                                        variant='contained'
                                        color='success'
                                        onClick={() => navigate('/account/signup')}
                                    >
                                        Regístrese, es gratis
                                    </Button>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box sx={{
                                        position: 'relative',
                                        width: '100%',
                                        '& img': {
                                            display: { xs: 'none', sm: 'block' }
                                        }
                                    }}>
                                        <Button
                                            fullWidth
                                            sx={{
                                                borderRadius: '2rem',
                                                py: 1.5,
                                                pr: { sm: 8 },
                                                fontSize: '1rem',
                                                textTransform: 'none'
                                            }}
                                            variant='contained'
                                            onClick={() => navigate('/chat')}
                                        >
                                            Hola soy EvBot tu experto virtual
                                        </Button>
                                        <Box sx={{
                                            position: 'absolute',
                                            right: { sm: 10 },
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            width: 50,
                                            height: 50
                                        }}>
                                            <img src={robot} alt='robot' style={{ width: '100%', height: 'auto' }} />
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>

                            {/* Tarjetas de servicios */}
                            <Grid container spacing={2} sx={{
                                justifyContent: 'center',
                                '& .MuiGrid-item': {  // Asegura items no excedan el ancho
                                    maxWidth: '100%',
                                    flexBasis: 'auto'
                                }
                            }}>
                                {[
                                    { icon: <Difference fontSize='large' />, title: 'Diagnostic Tool', subtitle: 'Manuales de Herramientas', link: '/manuales' },
                                    { icon: <SupportAgent fontSize='large' />, title: 'Asesoria Tecnica', subtitle: 'Consultoria de Negocios', link: '/asesoria_tecnica' },
                                    { icon: <Handyman fontSize='large' />, title: 'Reparar su Vehiculo', subtitle: 'Hibrido - Electrico', link: '/reparar_vehiculo_hibrido' },
                                    { icon: <Biotech fontSize='large' />, title: 'Forme Parte de la Red', subtitle: 'de Talleres y Laboratorios', link: '/account/signup' },
                                ].map((service, index) => (
                                    <Grid item xs={12} sm={6} lg={3} key={index} sx={{ display: 'flex' }}>
                                        <Card
                                            sx={{
                                                borderRadius: '2rem',
                                                minHeight: 140,
                                                flex: 1,
                                                backgroundColor: '#E8F0FE',
                                                transition: 'transform 0.2s',
                                                '&:hover': { transform: 'scale(1.02)' }
                                            }}
                                        >
                                            <CardActionArea
                                                onClick={() => navigate(service.link)}
                                                sx={{ height: '100%', p: 1.5 }}
                                            >
                                                <CardContent sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    textAlign: 'center'
                                                }}>
                                                    <Box sx={{ color: 'primary.main', fontSize: 40, mb: 1 }}>
                                                        {service.icon}
                                                    </Box>
                                                    <Typography variant='body2' sx={{ fontWeight: 600 }}>
                                                        {service.title}
                                                    </Typography>
                                                    <Typography variant='body2' sx={{ fontWeight: 600 }}>
                                                        {service.subtitle}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}