import React, { useState, useEffect } from 'react'
import { Container, Box, Typography, Toolbar } from '@mui/material';
import { AsesoriasPage } from './AsesoriasPage';
import { useSelector } from 'react-redux';
import { auth, db, firebase } from "../../db/firebase";
export const Dashboard = () => {
    const userAuth = useSelector(state => state.userAuth)
    const { loading, userInfo } = userAuth
 
    return (
        <>
       
            <Container maxWidth={userInfo?.roles?.find(role => ['admin']?.includes(role)) ? 'false' : 'lg'}>
                <Toolbar></Toolbar>
                <Box pb={5}>
                    <Typography align='center' variant='h3' gutterBottom>
                        <strong>Bienvenido, {userInfo?.nombres}. &#x1F44B;</strong>
                    </Typography>
                </Box>
                {
                    !userInfo?.roles?.find(role => ['admin']?.includes(role)) && <AsesoriasPage />
                }
            </Container>
        </>
    )
}