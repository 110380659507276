import React, { useState, useEffect } from 'react'
import { CssBaseline } from '@mui/material'
import {
    Routes,
    Route,
    BrowserRouter
} from "react-router-dom"
import { useDispatch } from 'react-redux'
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles'
import { auth, db } from '../db/firebase'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import PrivateRoute from './PrivateRoute'
import PublicRoute from './PublicRoute'
import { CircularLoading } from '../components/utils/CircularLoading'
import { Navbar } from '../components/utils/navbar/Navbar'
import { LoginPage } from '../pages/authentication/LoginPage'
import { ConsultoriaNegocioPage } from '../pages/asesoria_tecnica/consultoria_negocio/ConsultoriaNegocioPage'
import { TallerPage } from '../pages/talleres/TallerPage'
import { Dashboard } from '../pages/dashboard/Dashboard'
import { AsesoriaTecnicaPage } from '../pages/asesoria_tecnica/AsesoriaTecnicaPage'
import { RepararVehiculoHibrido } from '../pages/reparar_vehiculo_hibrido/RepararVehiculoHibrido'
import { SignupPage } from '../pages/authentication/SignupPage'
import { HibridoPage } from '../pages/vehiculo/hibrido/HibridoPage'
import { CargaManualesPage } from '../pages/carga_manuales/CargaManualesPage'
import { ManualesPage } from '../pages/carga_manuales/ManualesPage'
import ChangePicture from '../pages/account/ChangePicture'
import { InformeVehiculoHibridoPage } from '../pages/admin/informe_tecnico/InformeVehiculoHibridoPage'
import { ControladorEtapa } from '../pages/informe_tecnico/ControladorEtapa'
import { CrearInformeTecnico } from '../pages/informe_tecnico/CrearInformeTecnico'
import { CartPage } from '../pages/account/CartPage'
import { PasarelaPagoInfo } from '../pages/informe_tecnico/PasarelaPagoInfo'
import { OrderInfo } from '../pages/informe_tecnico/OrderInfo'
import { InformeTecnicoVH1 } from '../pages/admin/informe_tecnico/InformeTecnicoVH1'
import { AesoriaTecnicaList } from '../pages/admin/asesoria_tecnica/AesoriaTecnicaList'
import { RepararVehiculoList } from '../pages/admin/reparar_vehiculo/RepararVehiculoList'
import { UsuariosList } from '../pages/admin/administracion/UsuariosList'
import { ChatBot } from '../pages/admin/informe_tecnico/ChatBot'
import { OrdenTrabajoPage } from '../pages/admin/orden_trabajo/OrdenTrabajoPage'
import { CrearOrdenTrabajo } from '../pages/orden_trabajo/CrearOrdenTrabajo'
import { OrdenTrabajoAdminPage } from '../pages/admin/orden_trabajo/OrdenTrabajoAdminPage'
import { ChatBotPage } from '../pages/admin/informe_tecnico/ChatBotPage'
import Swal from 'sweetalert2'
import { EvaluacionBateriaPage } from '../pages/informe_tecnico/bateria/EvaluacionBateriaPage'
import { HomePage } from '../pages/authentication/HomePage'
import { PlanesPage } from '../pages/dashboard/PlanesPage'
export const AppRouters = () => {
    let theme = createTheme({
        palette: {
            primary: {
                main: '#007bff',
            },
            secondary: {
                main: '#007bff'
            },
            background: {
                default: '#f0f2f5'
                //default: '#ffff'
            },
            paper: {
                main: '#f0f2f5',
            }
        },
        shape: {
            //borderRadius: 10
        },
        components: {
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        backgroundColor: "#111827",
                        color: '#c2c7d0',
                        boxSizing: "border-box"
                    }
                }
            }
        }
    });
    theme = responsiveFontSizes(theme)
    const dispatch = useDispatch()
    const [checking, setChecking] = useState(true)
    const [isAuth, setIsAuth] = useState(false)
    useEffect(() => {
        auth.onAuthStateChanged(userAuth => {
            console.log(userAuth, 'userAuth')
            //?.emailVerified === true
            if (userAuth) {
                const userDocRef = db.collection('usuarios').doc(`${userAuth.email}`);
                userDocRef.onSnapshot((doc) => {
                    if (doc.exists) {
                        const dataClient = doc.data()
                        if (dataClient.verified) {
                            console.log(dataClient.credits, ' data client')
                            const dataT = {
                                ...dataClient,
                                credits: dataClient.credits,
                                displayName: dataClient.nombres,
                                id: userAuth.uid
                            }
                            setChecking(false)
                            setIsAuth(true)
                            dispatch({
                                type: 'AUTH_ADD_ITEMS',
                                payload: dataT,
                            })
                        } else {
                            console.log('NO ESTA AUTORIZADO')
                            Swal.fire({
                                icon: 'warning',
                                showConfirmButton: false,
                                text: "No puedes iniciar sesión en este momento. Tu acceso no ha sido autorizado por el administrador del sistema."
                            })
                            auth.signOut()
                        }
                    } else {
                        console.log('NO SE ENCONTRO USUARIO')
                        auth.signOut()
                    }
                })
            } else {
                auth.signOut()
                setChecking(false)
                setIsAuth(false)
            }
            //setChecking(false)
        });
    }, [setIsAuth, setChecking, dispatch])
    if (checking) {
        return (<CircularLoading />)
    }
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Navbar isAuth={isAuth}>
                    <Routes>
                        <Route path='/' element={
                            <PrivateRoute isAuth={isAuth}>
                                <Dashboard />
                            </PrivateRoute>
                        } />
                        <Route path='chat' element={
                            <ChatBotPage />
                        } />
                        <Route path='planes' element={
                            <PlanesPage />
                        } />
                        <Route path='asesoria_tecnica' element={
                            <AsesoriaTecnicaPage />
                        } />
                        <Route path='reparar_vehiculo_hibrido' element={
                            <RepararVehiculoHibrido />
                        } />
                        <Route path='manuales' element={
                            <ManualesPage />
                        } />
                        <Route path='account'>
                            <Route path='home' element={
                                <PublicRoute isAuth={isAuth}>
                                    <HomePage />
                                </PublicRoute>
                            } />
                            <Route path='login' element={
                                <PublicRoute isAuth={isAuth}>
                                    <LoginPage />
                                </PublicRoute>
                            } />
                            <Route path='signup' element={
                                <PublicRoute isAuth={isAuth}>
                                    <SignupPage />
                                </PublicRoute>
                            } />
                            <Route path='edit' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ChangePicture />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path='cart' element={
                            <CartPage />
                        } />
                        <Route path='checkout' element={
                            <PasarelaPagoInfo />
                        } />
                        <Route path='payment' element={
                            <ControladorEtapa />
                        } />
                        <Route path='order/:id' element={
                            <OrderInfo />
                        } />
                        <Route path='admin'>
                            <Route path='usuarios_autorizacion' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <UsuariosList />
                                </PrivateRoute>
                            } />
                            <Route path='informe'>
                                <Route path='vehiculo' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <InformeTecnicoVH1 />
                                    </PrivateRoute>
                                } />
                                <Route path='orden' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <OrdenTrabajoAdminPage />
                                    </PrivateRoute>
                                } />
                            </Route>
                            <Route path='asesoria/tecnica' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <AesoriaTecnicaList />
                                </PrivateRoute>
                            } />
                            <Route path='reparar/vehiculo' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <RepararVehiculoList />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path='asesoria_tecnica'>
                            <Route path='consultoria_negocios' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <ConsultoriaNegocioPage />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path='orden'>
                            <Route path='trabajo'>
                                <Route path='list' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <OrdenTrabajoPage />
                                    </PrivateRoute>
                                } />
                                <Route path='create' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <CrearOrdenTrabajo />
                                    </PrivateRoute>
                                } />
                            </Route>
                        </Route>
                        <Route path='evaluacion'>
                            <Route path='bateria'>
                                <Route path='list' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <EvaluacionBateriaPage />
                                    </PrivateRoute>
                                } />
                            </Route>
                        </Route>
                        <Route path='informe'>
                            <Route path='list' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <HibridoPage />
                                </PrivateRoute>
                            } />
                            <Route path='crear' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CrearInformeTecnico />
                                </PrivateRoute>
                            } />
                            <Route path=':action/:id' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CrearInformeTecnico />
                                </PrivateRoute>
                            } />
                            <Route path='vehiculo_hibrido' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <InformeVehiculoHibridoPage />
                                </PrivateRoute>
                            } />
                            <Route path='tecnico/:id/' element={
                                <PrivateRoute isAuth={isAuth}>
                                    <CrearInformeTecnico />
                                </PrivateRoute>
                            } />

                        </Route>
                        <Route path='carga_manuales/:id' element={
                            <PrivateRoute isAuth={isAuth}>
                                <CargaManualesPage />
                            </PrivateRoute>
                        } />
                        <Route path='vehiculo'>
                            <Route path='hibrido'>
                                <Route path='create' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <CrearInformeTecnico />
                                    </PrivateRoute>
                                } />
                                <Route path='list' element={
                                    <PrivateRoute isAuth={isAuth}>
                                        <HibridoPage />
                                    </PrivateRoute>
                                } />
                            </Route>
                        </Route>
                        <Route path='talleres' element={
                            <PrivateRoute isAuth={isAuth}>
                                <TallerPage />
                            </PrivateRoute>
                        }>
                        </Route>
                    </Routes>
                </Navbar>
                <ChatBot />
            </ThemeProvider>
        </BrowserRouter >
    )
}