import React from "react";
import iconrobot from '../../../assets/img/robot.png'
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Paper, Typography } from "@mui/material";
export const ChatBot = () => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        location.pathname !== '/chat' &&
        <div style={{ position: "fixed", bottom: 200, right: 25, zIndex: 9900 }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ paddingBottom: 4 }}>
                    <Paper sx={{ borderRadius: 20, textAlign: 'center' }}>
                        <Typography>
                            <strong>Ev Bot</strong>
                        </Typography>
                    </Paper>
                </div>
                <Button
                    variant='contained'
                    color='inherit'
                    sx={{ borderRadius: 20 }}
                    onClick={() => navigate('/chat')}
                >
                    <img
                        src={iconrobot}
                        width={50}
                        alt=''
                        style={{ paddingTop: 10, paddingBottom: 10 }}
                    />
                </Button>
            </div>
        </div>
    );
};
